import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { Index1Component } from './dashboard/index1/index1.component';
import { Index2Component } from './dashboard/index2/index2.component';
import { Index3Component } from './dashboard/index3/index3.component';
import { Index4Component } from './dashboard/index4/index4.component';

import { CoinDetailsComponent } from './coin-details/coin-details.component';
import { MyWalletsComponent } from './my-wallets/my-wallets.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PortofolioComponent } from './portofolio/portofolio.component';
import { MarketCapitalComponent } from './market-capital/market-capital.component';

import { ProfileComponent } from './apps/profile/profile.component';
import { PostDetailsComponent } from './apps/post-details/post-details.component';
import { ComposeComponent } from './apps/email/compose/compose.component';
import { InboxComponent } from './apps/email/inbox/inbox.component';
import { ReadComponent } from './apps/email/read/read.component';
import { CalenderComponent } from './apps/calender/calender.component';
import { ProductGridComponent } from './apps/shop/product-grid/product-grid.component';
import { ProductListComponent } from './apps/shop/product-list/product-list.component';
import { ProductDetailComponent } from './apps/shop/product-detail/product-detail.component';
import { OrderComponent } from './apps/shop/order/order.component';
import { CheckoutComponent } from './apps/shop/checkout/checkout.component';
import { InvoiceComponent } from './apps/shop/invoice/invoice.component';
import { EcomCustomersComponent } from './apps/shop/ecom-customers/ecom-customers.component';

import { ApexComponent } from './charts/apex/apex.component';
import { LineComponent } from './charts/apex/line/line.component';
import { AreaComponent } from './charts/apex/area/area.component';
import { ColumnComponent } from './charts/apex/column/column.component';
import { BarComponent } from './charts/apex/bar/bar.component';
import { MixedComponent } from './charts/apex/mixed/mixed.component';
import { TimelineComponent } from './charts/apex/timeline/timeline.component';
import { CandlestickComponent } from './charts/apex/candlestick/candlestick.component';
import { PieComponent } from './charts/apex/pie/pie.component';
import { RadarComponent } from './charts/apex/radar/radar.component';
import { RadialbarComponent } from './charts/apex/radialbar/radialbar.component';
import { PolarAreaComponent } from './charts/apex/polar-area/polar-area.component';
import { BubbleComponent } from './charts/apex/bubble/bubble.component';
import { ScatterComponent } from './charts/apex/scatter/scatter.component';
import { HeatmapComponent } from './charts/apex/heatmap/heatmap.component';
import { TreemapComponent } from './charts/apex/treemap/treemap.component';
import { SparklinesComponent } from './charts/apex/sparklines/sparklines.component';
import { ChartjsComponent } from './charts/chartjs/chartjs.component';

import { AccordionComponent } from './bootstrap/accordion/accordion.component';
import { AlertComponent } from './bootstrap/alert/alert.component';
import { ButtonComponent } from './bootstrap/button/button.component';
import { ModalComponent } from './bootstrap/modal/modal.component';
import { BadgeComponent } from './bootstrap/badge/badge.component';
import { ButtonGroupComponent } from './bootstrap/button-group/button-group.component';
import { ListGroupComponent } from './bootstrap/list-group/list-group.component';
import { CardsComponent } from './bootstrap/cards/cards.component';
import { CarouselComponent } from './bootstrap/carousel/carousel.component';
import { DatepickerComponent } from './bootstrap/datepicker/datepicker.component';
import { DropdownComponent } from './bootstrap/dropdown/dropdown.component';
import { MediaObjectComponent } from './bootstrap/media-object/media-object.component';
import { NavComponent } from './bootstrap/nav/nav.component';
import { PaginationComponent } from './bootstrap/pagination/pagination.component';
import { PopoverComponent } from './bootstrap/popover/popover.component';
import { ProgressbarComponent } from './bootstrap/progressbar/progressbar.component';
import { RatingComponent } from './bootstrap/rating/rating.component';
import { TableComponent } from './bootstrap/table/table.component';
import { TimepickerComponent } from './bootstrap/timepicker/timepicker.component';
import { ToastComponent } from './bootstrap/toast/toast.component';
import { TooltipComponent } from './bootstrap/tooltip/tooltip.component';
import { TypeaheadComponent } from './bootstrap/typeahead/typeahead.component';
import { TypographyComponent } from './bootstrap/typography/typography.component';
import { GridComponent } from './bootstrap/grid/grid.component';

import { DzmtAutocompleteComponent } from './material/dzmt-autocomplete/dzmt-autocomplete.component';
import { DzmtBadgeComponent } from './material/dzmt-badge/dzmt-badge.component';
import { DzmtBottomSheetComponent } from './material/dzmt-bottom-sheet/dzmt-bottom-sheet.component';
import { DzmtButtonComponent } from './material/dzmt-button/dzmt-button.component';
import { DzmtButtonToggleComponent } from './material/dzmt-button-toggle/dzmt-button-toggle.component';
import { DzmtCardComponent } from './material/dzmt-card/dzmt-card.component';
import { DzmtCheckboxComponent } from './material/dzmt-checkbox/dzmt-checkbox.component';
import { DzmtChipsComponent } from './material/dzmt-chips/dzmt-chips.component';
import { DzmtDatepickerComponent } from './material/dzmt-datepicker/dzmt-datepicker.component';
import { DzmtDialogComponent } from './material/dzmt-dialog/dzmt-dialog.component';
import { DzmtDividerComponent } from './material/dzmt-divider/dzmt-divider.component';
import { DzmtExpansionComponent } from './material/dzmt-expansion/dzmt-expansion.component';
import { DzmtFormFieldComponent } from './material/dzmt-form-field/dzmt-form-field.component';
import { DzmtGridListComponent } from './material/dzmt-grid-list/dzmt-grid-list.component';
import { DzmtIconComponent } from './material/dzmt-icon/dzmt-icon.component';
import { DzmtInputComponent } from './material/dzmt-input/dzmt-input.component';
import { DzmtListComponent } from './material/dzmt-list/dzmt-list.component';
import { DzmtMenuComponent } from './material/dzmt-menu/dzmt-menu.component';
import { DzmtPaginatorComponent } from './material/dzmt-paginator/dzmt-paginator.component';
import { DzmtProgressBarComponent } from './material/dzmt-progress-bar/dzmt-progress-bar.component';
import { DzmtProgressSpinnerComponent } from './material/dzmt-progress-spinner/dzmt-progress-spinner.component';
import { DzmtRadioComponent } from './material/dzmt-radio/dzmt-radio.component';
import { DzmtRippleComponent } from './material/dzmt-ripple/dzmt-ripple.component';
import { DzmtSelectComponent } from './material/dzmt-select/dzmt-select.component';
import { DzmtSidenavComponent } from './material/dzmt-sidenav/dzmt-sidenav.component';
import { DzmtSlideToggleComponent } from './material/dzmt-slide-toggle/dzmt-slide-toggle.component';
import { DzmtSliderComponent } from './material/dzmt-slider/dzmt-slider.component';
import { DzmtSnackBarComponent } from './material/dzmt-snack-bar/dzmt-snack-bar.component';
import { DzmtSortComponent } from './material/dzmt-sort/dzmt-sort.component';
import { DzmtStepperComponent } from './material/dzmt-stepper/dzmt-stepper.component';
import { DzmtTableComponent } from './material/dzmt-table/dzmt-table.component';
import { DzmtTabsComponent } from './material/dzmt-tabs/dzmt-tabs.component';
import { DzmtTooltipComponent } from './material/dzmt-tooltip/dzmt-tooltip.component';
import { DzmtTreeComponent } from './material/dzmt-tree/dzmt-tree.component';
import { DzmtToolbarComponent } from './material/dzmt-toolbar/dzmt-toolbar.component';

import { NestableComponent } from './plugins/nestable/nestable.component';
import { LightGalleryComponent } from './plugins/light-gallery/light-gallery.component';

import { WidgetComponent } from './widget/widget.component';

import { ElementsComponent } from './forms/elements/elements.component';
import { FormValidateComponent } from './forms/form-validate/form-validate.component';

import { MstRoleComponent } from './master/roles/view/mst-role.component';
import { MstRolesdetailComponent } from './master/rolesdetail/view/mst-rolesdetail.component';
import { MstMenuComponent } from './master/menus/view/mst-menu.component';
import { MstUserComponent } from './master/users/view/mst-user.component';
import { MstTypeitemsComponent } from './master/type-items/view/mst-typeitems.component';
import { MstSubtypeitemComponent } from './master/type-item-subs/view/mst-subtypeitem.component';
import { MstShippersComponent } from './master/shippers/view/mst-shippers.component';
import { MstShipperspriceComponent } from './master/shippersprice/view/mst-shippersprice.component';
import { MstVendorsComponent } from './master/vendors/view/mst-vendors.component';
import { MstVendorsresiComponent } from './master/vendorsresi/view/mst-vendorsresi.component';
import { MstConsigneeComponent } from './master/consignees/view/mst-consignee.component';
import { MstTrackingComponent } from './master/tracking/view/mst-tracking.component';
import { MstTrackingLokalComponent } from './master/trackinglokal/view/mst-trackinglokal.component';
import { MstContactusComponent } from './master/contact-us/view/mst-contact-us.component';
import { MstBankComponent } from './master/bank/view/mst-bank.component';
import { MstRekeningbankComponent } from './master/rekening-bank/view/mst-rekening-bank.component';
import { MstCountryComponent } from './master/country/view/mst-country.component';
import { MstCityComponent } from './master/city/view/mst-city.component';
import { MstFlightsComponent } from './master/flights/view/mst-flights.component';
import { MstKurirComponent } from './master/kurir/view/mst-kurir.component';

import { MstSlidersComponent } from './master/sliders/view/mst-sliders.component';
import { MstNewsComponent } from './master/news/view/mst-news.component';
import { MstDaftarhargaComponent } from './master/daftarharga/view/mst-daftarharga.component';
import { MstKategorisComponent } from './master/kategoris/view/mst-kategoris.component';
import { MstProductsComponent } from './master/products/view/mst-products.component';

import { TrInboundprosesComponent } from './transaction/inboundproses/view/tr-inboundproses.component';
import { AddinboundprosesComponent } from './transaction/inboundproses/form/add-inboundproses.component';
import { TrInboundComponent } from './transaction/inbound/view/tr-inbound.component';
import { AddinboundComponent } from './transaction/inbound/form/add-inbound.component';
import { TrCleansingsComponent } from './transaction/cleansings/view/tr-cleansings.component';
import { TrCleansingscompleteComponent } from './transaction/cleansingscomplete/view/tr-cleansingscomplete.component';
import { TrManifestComponent } from './transaction/manifest/view/tr-manifest.component';
import { TrManifestbagComponent } from './transaction/manifest-bag/view/tr-manifest-bag.component';
import { TrManifestbagdetailComponent } from './transaction/manifest-bag-detail/view/tr-manifest-bag-detail.component';
import { TrTagihanComponent } from './transaction/tagihan/view/tr-tagihan.component';
import { TrTagihanpaidComponent } from './transaction/tagihanpaid/view/tr-tagihanpaid.component';

import { RpInboundComponent } from './reports/inbound/view/rp-inbound.component';
import { RpCleansingsComponent } from './reports/cleansings/view/rp-cleansings.component';
import { RpManifestComponent } from './reports/manifest/view/rp-manifest.component';
import { RpTagihanComponent } from './reports/tagihan/view/rp-tagihan.component';
import { RpTagihanshipperComponent } from './reports/tagihanshipper/view/rp-tagihanshipper.component';
import { RpPembayaranComponent } from './reports/pembayaran/view/rp-pembayaran.component';

import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';

import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/error400/error400.component';
import { Error403Component } from './pages/error403/error403.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';
import { CleansingFormComponent } from './transaction/cleansings/cleansing-form/cleansing-form.component';
import { CleansingPrintComponent } from './transaction/cleansings/cleansing-print/cleansing-print.component';


const routes: Routes = [
  // { path: '', redirectTo: 'admin/index', pathMatch: 'full' },
  // { path: 'page-login', component: LoginComponent },
  { path: '', redirectTo: 'page-login', pathMatch: 'full' },
  {
    path: 'admin', component: AdminComponent, children: [
      { path: '', component: Index1Component },
      { path: 'index', component: Index1Component },
      { path: 'index-1', component: Index1Component },
      { path: 'dashboard', component: Index1Component },
      { path: 'index-2', component: Index2Component },
      { path: 'dashboard-2', component: Index2Component },
      { path: 'index-3', component: Index3Component },
      { path: 'dashboard-3', component: Index3Component },
      { path: 'index-4', component: Index4Component },
      { path: 'dashboard-4', component: Index4Component },

      { path: 'coin-details', component: CoinDetailsComponent },
      { path: 'my-wallets', component: MyWalletsComponent },
      { path: 'transactions', component: TransactionsComponent },
      { path: 'portofolio', component: PortofolioComponent },
      { path: 'market-capital', component: MarketCapitalComponent },

      { path: 'app-profile', component: ProfileComponent },
      { path: 'post-details', component: PostDetailsComponent },
      { path: 'email-compose', component: ComposeComponent },
      { path: 'email-inbox', component: InboxComponent },
      { path: 'email-read', component: ReadComponent },
      { path: 'app-calender', component: CalenderComponent },

      { path: 'ecom-product-grid', component: ProductGridComponent },
      { path: 'ecom-product-list', component: ProductListComponent },
      { path: 'ecom-product-detail', component: ProductDetailComponent },
      { path: 'ecom-product-order', component: OrderComponent },
      { path: 'ecom-checkout', component: CheckoutComponent },
      { path: 'ecom-invoice', component: InvoiceComponent },
      { path: 'ecom-customers', component: EcomCustomersComponent },

      { path: 'chart-apex', component: ApexComponent },
      { path: 'apex-line', component: LineComponent },
      { path: 'apex-area', component: AreaComponent },
      { path: 'apex-column', component: ColumnComponent },
      { path: 'apex-bar', component: BarComponent },
      { path: 'apex-mixed', component: MixedComponent },
      { path: 'apex-timeline', component: TimelineComponent },
      { path: 'apex-candlestick', component: CandlestickComponent },
      { path: 'apex-pie', component: PieComponent },
      { path: 'apex-radar', component: RadarComponent },
      { path: 'apex-radialbar', component: RadialbarComponent },
      { path: 'apex-polar-area', component: PolarAreaComponent },
      { path: 'apex-bubble', component: BubbleComponent },
      { path: 'apex-scatter', component: ScatterComponent },
      { path: 'apex-heatmap', component: HeatmapComponent },
      { path: 'apex-treemap', component: TreemapComponent },
      { path: 'apex-sparklines', component: SparklinesComponent },
      { path: 'chart-chartjs', component: ChartjsComponent },

      { path: 'ui-accordion', component: AccordionComponent },
      { path: 'ui-alert', component: AlertComponent },
      { path: 'ui-button', component: ButtonComponent },
      { path: 'ui-modal', component: ModalComponent },
      { path: 'ui-badge', component: BadgeComponent },
      { path: 'ui-button-group', component: ButtonGroupComponent },
      { path: 'ui-list-group', component: ListGroupComponent },
      { path: 'ui-card', component: CardsComponent },
      { path: 'ui-carousel', component: CarouselComponent },
      { path: 'ui-datepicker', component: DatepickerComponent },
      { path: 'ui-dropdown', component: DropdownComponent },
      { path: 'ui-media-object', component: MediaObjectComponent },
      { path: 'ui-nav', component: NavComponent },
      { path: 'ui-pagination', component: PaginationComponent },
      { path: 'ui-popover', component: PopoverComponent },
      { path: 'ui-progressbar', component: ProgressbarComponent },
      { path: 'ui-rating', component: RatingComponent },
      { path: 'ui-table', component: TableComponent },
      { path: 'ui-timepicker', component: TimepickerComponent },
      { path: 'ui-toast', component: ToastComponent },
      { path: 'ui-tooltip', component: TooltipComponent },
      { path: 'ui-typeahead', component: TypeaheadComponent },
      { path: 'ui-typography', component: TypographyComponent },
      { path: 'ui-grid', component: GridComponent },

      { path: 'mat-autocomplete', component: DzmtAutocompleteComponent },
      { path: 'mat-badge', component: DzmtBadgeComponent },
      { path: 'mat-bottom-sheet', component: DzmtBottomSheetComponent },
      { path: 'mat-button', component: DzmtButtonComponent },
      { path: 'mat-button-toggle', component: DzmtButtonToggleComponent },
      { path: 'mat-card', component: DzmtCardComponent },
      { path: 'mat-checkbox', component: DzmtCheckboxComponent },
      { path: 'mat-chips', component: DzmtChipsComponent },
      { path: 'mat-datepicker', component: DzmtDatepickerComponent },
      { path: 'mat-dialog', component: DzmtDialogComponent },
      { path: 'mat-divider', component: DzmtDividerComponent },
      { path: 'mat-expansion', component: DzmtExpansionComponent },
      { path: 'mat-form-field', component: DzmtFormFieldComponent },
      { path: 'mat-grid-list', component: DzmtGridListComponent },
      { path: 'mat-icon', component: DzmtIconComponent },
      { path: 'mat-input', component: DzmtInputComponent },
      { path: 'mat-list', component: DzmtListComponent },
      { path: 'mat-menu', component: DzmtMenuComponent },
      { path: 'mat-paginator', component: DzmtPaginatorComponent },
      { path: 'mat-progress-bar', component: DzmtProgressBarComponent },
      { path: 'mat-progress-spinner', component: DzmtProgressSpinnerComponent },
      { path: 'mat-radio', component: DzmtRadioComponent },
      { path: 'mat-ripple', component: DzmtRippleComponent },
      { path: 'mat-select', component: DzmtSelectComponent },
      { path: 'mat-sidenav', component: DzmtSidenavComponent },
      { path: 'mat-slide-toggle', component: DzmtSlideToggleComponent },
      { path: 'mat-slider', component: DzmtSliderComponent },
      { path: 'mat-snack-bar', component: DzmtSnackBarComponent },
      { path: 'mat-sort', component: DzmtSortComponent },
      { path: 'mat-stepper', component: DzmtStepperComponent },
      { path: 'mat-table', component: DzmtTableComponent },
      { path: 'mat-tab', component: DzmtTabsComponent },
      { path: 'mat-tooltip', component: DzmtTooltipComponent },
      { path: 'mat-tree', component: DzmtTreeComponent },
      { path: 'mat-toolbar', component: DzmtToolbarComponent },

      { path: 'uc-nestable', component: NestableComponent },
      { path: 'uc-lightgallery', component: LightGalleryComponent },

      { path: 'widget-basic', component: WidgetComponent },

      { path: 'form-element', component: ElementsComponent },
      { path: 'form-validate', component: FormValidateComponent },

      { path: 'app-mst-role', component: MstRoleComponent },
      { path: 'app-mst-rolesdetail/:postId', component: MstRolesdetailComponent },
      { path: 'app-mst-user', component: MstUserComponent },
      { path: 'app-mst-menu', component: MstMenuComponent },
      { path: 'app-mst-typeitems', component: MstTypeitemsComponent },
      { path: 'app-mst-subtypeitem', component: MstSubtypeitemComponent },
      { path: 'app-mst-shippers', component: MstShippersComponent },
      { path: 'app-mst-shippersprice/:shippers_id', component: MstShipperspriceComponent },
      { path: 'app-mst-vendors', component: MstVendorsComponent },
      { path: 'app-mst-vendorsresi', component: MstVendorsresiComponent },
      { path: 'app-mst-consignee', component: MstConsigneeComponent },
      { path: 'app-mst-tracking', component: MstTrackingComponent },
      { path: 'app-mst-trackinglokal', component: MstTrackingLokalComponent },
      { path: 'app-mst-contact-us', component: MstContactusComponent },
      { path: 'app-mst-bank', component: MstBankComponent },
      { path: 'app-mst-rekening-bank', component: MstRekeningbankComponent },
      { path: 'app-mst-country', component: MstCountryComponent },
      { path: 'app-mst-city', component: MstCityComponent },
      { path: 'app-mst-flights', component: MstFlightsComponent },
      { path: 'app-mst-kurir', component: MstKurirComponent },

      { path: 'app-mst-sliders', component: MstSlidersComponent },
      { path: 'app-mst-news', component: MstNewsComponent },
      { path: 'app-mst-daftarharga', component: MstDaftarhargaComponent },
      { path: 'app-mst-kategoris', component: MstKategorisComponent },
      { path: 'app-mst-products', component: MstProductsComponent },

      { path: 'app-tr-inboundproses', component: TrInboundprosesComponent },
      { path: 'app-add-inboundproses', component: AddinboundprosesComponent },
      { path: 'app-tr-inbound', component: TrInboundComponent },
      { path: 'app-add-inbound', component: AddinboundComponent },
      { path: 'app-tr-cleansings', component: TrCleansingsComponent },
      { path: 'app-tr-cleansingscomplete', component: TrCleansingscompleteComponent },
      { path: 'form-cleansing', component: CleansingFormComponent },
      { path: 'cleansing-print', component: CleansingPrintComponent },
      { path: 'app-tr-manifest', component: TrManifestComponent },
      { path: 'app-tr-manifest-bag/:postId', component: TrManifestbagComponent },
      // { path: 'app-tr-manifest-bag-detail/:postId', component: TrManifestbagdetailComponent },
      { path: 'app-tr-manifest-bag-detail/:idbag/manifest-bag/:idmanifest', component: TrManifestbagdetailComponent },
      { path: 'app-tr-tagihan', component: TrTagihanComponent },
      { path: 'app-tr-tagihanpaid', component: TrTagihanpaidComponent },
      // { path: 'app-tr-manifest-bag', component: TrManifestbagComponent },

      { path: 'app-rp-inbound', component: RpInboundComponent },
      { path: 'app-rp-cleansings', component: RpCleansingsComponent },
      { path: 'app-rp-manifest', component: RpManifestComponent },
      { path: 'app-rp-tagihan', component: RpTagihanComponent },
      { path: 'app-rp-tagihanshipper', component: RpTagihanshipperComponent },
      { path: 'app-rp-pembayaran', component: RpPembayaranComponent },

    ]
  },

  { path: 'page-register', component: RegisterComponent },
  { path: 'page-login', component: LoginComponent },
  { path: 'page-lock-screen', component: LockScreenComponent },
  { path: 'page-forgot-password', component: ForgotPasswordComponent },
  { path: 'page-error-400', component: Error400Component },
  { path: 'page-error-403', component: Error403Component },
  { path: 'page-error-404', component: Error404Component },
  { path: 'page-error-500', component: Error500Component },
  { path: 'page-error-503', component: Error503Component },

  { path: '**', component: Error404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
